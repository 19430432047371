var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brands"},[_c('Menu',{attrs:{"title":"SKU брендов"}}),_c('div',{staticClass:"container"},[_c('Table',_vm._b({staticClass:"brands__table",scopedSlots:_vm._u([{key:"markCaption",fn:function(){return [_c('MarkItem',{staticClass:"brands__item-mark"})]},proxy:true},{key:"mark",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('MarkItem',{staticClass:"brands__item-mark",attrs:{"color":item.type === 'brand' ? 'blue' : 'orange'}})]}},{key:"title",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"brands__item-title",on:{"click":function($event){return _vm.openBrand(item)}}},[_vm._v(_vm._s(item.brandName))])]}},{key:"custom",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(_vm._s(item))]}}])},'Table',_vm.tableOptions,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }